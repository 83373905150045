<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Create New Plate')"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number ?false:null"
                  :class="errors.number ?'is-invalid':null"
                />
                <small
                  v-if="errors.number"
                  class="text-danger"
                >{{ errors.number[0] }}</small>
                <label>{{ $t('start') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="data.multi"
                  :value="true"
                  :state="errors.multi ?false:null"
                >
                  {{ $t('Multi') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.multi"
                class="text-danger"
              >{{ errors.multi[0] }}</small>
            </b-col>
            <b-col
              v-if="data.multi!==true"
              cols="12"
              md="2"
            >
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="data.available"
                  :value="true"
                  :state="errors.available ?false:null"
                >
                  {{ $t('Available') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.available"
                class="text-danger"
              >{{ errors.available[0] }}</small>
            </b-col>
            <b-col
              v-if="data.multi===true"
              cols="12"
              md="4"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.count"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberE')"
                  :state="errors.count ?false:null"
                  :class="errors.count ?'is-invalid':null"
                />
                <small
                  v-if="errors.count"
                  class="text-danger"
                >{{ errors.count[0] }}</small>
                <label>{{ $t('CountG') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Car_type') }}</label>
                <b-form-select
                  v-model="data.car_type"
                  :options="options"
                  label="title"
                />
                <small
                  v-if="errors.car_type"
                  class="text-danger"
                >{{ errors.car_type[0] }}</small>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="data={},errors=[]"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { value: 'tourism', text: 'سياحية' },
        { value: 'small', text: 'نقل صغيرة' },
        { value: 'big', text: 'نقل كبيرة' },
        { value: 'transport_ride', text: 'نقل وركوب' },
        { value: 'agricultural', text: 'زراعية' },
        { value: 'work', text: 'أشغال' },
        { value: 'microbus', text: 'ميكروباص' },
        { value: 'spicial', text: 'استعمال خاص' },
        { value: 'goverment', text: 'حكومية' },
        { value: 'bus', text: 'باص' },
        { value: 'transport', text: 'نقل' },
        { value: 'motor', text: 'دراجة نارية' },
      ],
      data: {
        multi: 'false',
        available: 'false',
        number: '',
        count: '',
      },
      errors: [],
      fileErrors: '',

    }
  },
  computed: {},
  watch: {
    // eslint-disable-next-line func-names
    'data.multi': function (val) {
      if (val === true) {
        this.data.available = false
      } else {
        this.data.count = ''
      }
    },
  },
  created() {
  },
  methods: {
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.errors = []
      this.$store.dispatch('platee/Create', this.data).then(() => {
        this.data = {
          multi: 'false',
          available: 'false',
          number: '',
          count: '',
        }
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
